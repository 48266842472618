import {Outlet} from "react-router-dom";
import {ReactRouterAppProvider} from "@toolpad/core/react-router";
import {Branding, Navigation} from "@toolpad/core";
import enLocaleText from "@toolpad/core/locales/en";
import {Theme} from "@mui/material";
import {useContext} from "react";
import {SessionContext} from "../authentication/SessionProvider";
import Session from "../authentication/Session";
import {User} from "../api-client/AppFwApiClient";


// TODO: install from somewhere (or use "resource bundle")?
// See: https://mui.com/toolpad/core/api/localization-provider/
const deDeToolpadLocaleText = {
    ...enLocaleText.components.MuiLocalizationProvider.defaultProps.localeText,

    accountSignOutLabel: "Abmelden",
}


interface AppFwAppProps {
    readonly theme?: Theme;
    readonly branding?: Branding;
    readonly navigation?: Navigation;
}

export default function AppFwApp(props: AppFwAppProps) {

    const session = useContext<undefined | null | Session>(SessionContext)

    let toolpadAuthentication = {
        signIn: () => {
            console.log("signIn?? Should not be called")
        },
        signOut: () => {
            session?.logout()
        },
    }

    let toolpadSession: null | Session = null
    if (session != null) {
        let toolpadSessionUser: User & { image?: string } = {
            ...session.user,
            image: session.user.avatarUrl,
            // TODO: email support?
        }
        toolpadSession = {
            ...session,
            // id: session.id,
            user: toolpadSessionUser,
            logout: toolpadAuthentication.signOut,
        }
    }

    return (
        <ReactRouterAppProvider
            localeText={deDeToolpadLocaleText}
            navigation={props.navigation}
            theme={props.theme}
            branding={props.branding}
            authentication={toolpadAuthentication}
            session={toolpadSession}
        >
            <Outlet/>
        </ReactRouterAppProvider>
    )
}