import {eventerPod} from "../client/eventer-pod-client.ts";
import {useContext, useEffect, useState} from "react";
import {ResourceWrapper} from "../client/ResourceWrapper.ts";
import Session from "../../appfw/authentication/Session.ts";
import {SessionContext} from "../../appfw/authentication/SessionProvider.tsx";

// simple state cache
const cache = new Map<string, ResourceWrapper[]>()
const inFlight = new Map<string, Promise<ResourceWrapper[]>>()


async function initPod(pod: string, sessionId: string): Promise<ResourceWrapper[]> {
    await eventerPod.init()
    const res = await eventerPod.loadPodEvents({ request: { pod }, sessionId: sessionId })
    return res.map((r) => new ResourceWrapper(r))
}

export interface useEventsProps {
    pod: string,
}

export function useEvents({pod}: useEventsProps) {

    const session = useContext<undefined | null | Session>(SessionContext)
    const [events, setEvents] = useState<undefined | ResourceWrapper[]>(undefined)

    useEffect(() => {

        let sessionId = session?.id
        if (!sessionId) {
            return
        }

        let entry = cache.get(pod)
        if (entry) {
            setEvents(entry)
            return
        }

        // "inFlight"-Tracking, to avoid parallel inits
        const pending = inFlight.get(pod)
        const loader = pending ?? initPod(pod, sessionId)

        inFlight.set(pod, loader)

        loader.then((res) => {
            if (!cache.has(pod)) {
                cache.set(pod, res)
                setEvents(res)
            }
            inFlight.delete(pod)
        })
    }, [pod, session])

    return { events }
}