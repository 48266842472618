import GCalListItem from "./GCalListItem.tsx";
import {Container, Fab} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddGCalIntegrationDialog from "./AddGCalIntegrationDialog.tsx";
import {useCallback, useEffect, useMemo, useState} from "react";
import {eventerConsoleApiClient, GCalIntegration} from "../../../../../api-client/EventerConsoleApiClient.ts";

export interface GCalListProps {
    accountId: string;
}

export default function GCalList(props: GCalListProps) {

    const [gCalIntegrations, setGCalIntegrations] = useState<GCalIntegration[]>([])

    useEffect(() => {
        let active = true
        eventerConsoleApiClient.getGCalIntegrations({accountId: props.accountId})
            .then((gCalIntegrations) => {
                if (active) {
                    setGCalIntegrations(gCalIntegrations)
                }
            })
        return () => {
            active = false
        }
    }, [props.accountId])

    // use memos / callbacks?
    const [open, setOpen] = useState(false);
    const handleClickOpen = useCallback(() => {
        setOpen(true);
    }, [])

    const handleClose = useCallback((addedGCalIntegration?: GCalIntegration) => {
        setOpen(false);
        if (addedGCalIntegration) {
            setGCalIntegrations([...gCalIntegrations, addedGCalIntegration])
        }
        console.log("closed: ", addedGCalIntegration)
    }, [gCalIntegrations])

    const handleUpdate = useCallback((updatedIntegration: GCalIntegration) => {
        let modified = false
        let updatedIntegrations = gCalIntegrations.map((integration) => {
            if (integration.id === updatedIntegration.id) {
                modified = true
                return updatedIntegration
            } else {
                return integration
            }
        })
        if (modified) {
            setGCalIntegrations(updatedIntegrations)
        }
    }, [gCalIntegrations])

    const handleDelete = useCallback((id: string) => {
        const updatedGCalIntegrations = gCalIntegrations
            .filter((it) => it.id !== id)
        if (updatedGCalIntegrations.length !== gCalIntegrations.length) {
            setGCalIntegrations(updatedGCalIntegrations)
        }
    }, [gCalIntegrations])

    const gCalEntries =
        gCalIntegrations.map((gCalIntegration) => {
            return <GCalListItem
                key={gCalIntegration.id}
                accountId={props.accountId}
                gCalIntegration={gCalIntegration}
                onUpdate={handleUpdate}
                onDelete={() => handleDelete(gCalIntegration.id)}
            />
        })

    const fabStyle = useMemo(() => {
        return {
            position: 'absolute',
            bottom: 16,
            right: 16,
        }
    }, [])

    return (
        <div>
            <Container maxWidth="md">
                {gCalEntries}
            </Container>
            <Fab color="primary" aria-label="add" sx={fabStyle} onClick={handleClickOpen}>
                <AddIcon/>
            </Fab>
            <AddGCalIntegrationDialog accountId={props.accountId} open={open} onClose={handleClose}/>
        </div>
    )
}
