import {Box, Typography} from "@mui/material";
import {useEvents} from "../../../../../eventer-pod/events/events.ts";
import {EventCard} from "./EventCard.tsx";
import {useAccount} from "../Accounts.tsx";

export default function EventsPage() {

    const account = useAccount()
    const eventsHook = useEvents({pod: account.podName})

    if (!eventsHook.events) {
        // TODO: loading spinner
        return <Typography>Lade Events...</Typography>
    }

    return (
        <Box
            sx={{
                // width: '97%',
                display: 'grid',
                // gridTemplateColumns: 'repeat(auto-fill, minmax(min(500px, 100%), 10fr))',
                gap: 2,
                margin: 2,
            }}
        >
            {
                eventsHook.events.map((event) => {
                    return <EventCard event={event} key={event.subject} />
                })
            }
        </Box>
    )
}
