import {Box, Button, Stack} from "@mui/material";
import {Apple, Google} from "@mui/icons-material";
import {useSearchParams} from "react-router-dom";
import {ReactNode, useMemo} from "react";
import {appFwApiClient} from "../../api-client/AppFwApiClient";
import {AuthenticationConfig} from "../../authentication/AuthenticationGuard";

interface SplashScreenProps {
    authenticationConfig: AuthenticationConfig,
    children?: ReactNode;
}

export default function SplashScreen(props: SplashScreenProps) {

    const [searchParams] = useSearchParams()

    const googleLoginEl = useMemo(() => {
        if (!props.authenticationConfig.oidcProviders.includes("google")) {
            return
        }
        let googleHref = appFwApiClient.startOidcLoginUrl(props.authenticationConfig.app, "google")
        const path = searchParams.get("path")
        if (path) {
            googleHref = googleHref + "?path=" + encodeURIComponent(path)
        }
        return (
            <Button
                href={googleHref}
                variant="contained"
                startIcon={<Google/>}
                sx={{
                    // We should use official Google colors
                    backgroundColor: '#4285F4',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#357ae8',
                    },
                    padding: '0.75rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    minWidth: '300px',
                }}
            >
                Mit Google anmelden
            </Button>
        )
    }, [props.authenticationConfig, searchParams])

    const appleLoginEl = useMemo(() => {
        if (!props.authenticationConfig.oidcProviders.includes("apple")) {
            return
        }
        let appleHref = appFwApiClient.baseUrl + "auth/oidc/w2d2d/apple/start"
        const path = searchParams.get("path")
        if (path) {
            appleHref = appleHref + "?path=" + encodeURIComponent(path)
        }
        return (
            <Button
                href={appleHref}
                variant="contained"
                startIcon={<Apple/>}
                sx={{
                    color: '#fff',
                    backgroundColor: '#000',
                    '&:hover': {
                        backgroundColor: '#333',
                    },
                    padding: '0.75rem',
                    paddingLeft: '2rem',
                    paddingRight: '2rem',
                    borderRadius: '8px',
                    textTransform: 'none',
                    fontWeight: 'bold',
                    fontSize: '1rem',
                    minWidth: '300px',
                }}
            >
                Mit Apple anmelden
            </Button>
        )
    }, [props.authenticationConfig, searchParams])

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: '#000',
            }}
        >

            {props.children}

            <Stack direction="column" spacing={2}>
                {appleLoginEl}
                {googleLoginEl}
            </Stack>
        </Box>
    )
}
