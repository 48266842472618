import GCalList from "./gcal/GCalList.tsx";
import {useAccount} from "../Accounts.tsx";

export default function ImportersPage() {

    const account = useAccount()

    return (
        <GCalList accountId={account.id}/>
    )
}
