import {eventerConsoleApiClient} from "../../../../api-client/EventerConsoleApiClient.ts";
import {useAccount} from "../Accounts.tsx";
import {Box, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {useCallback, useState} from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {DeleteAccountConfirmationDialog} from "./DeleteAccountConfirmationDialog.tsx";
import EventerRoutes from "../../../../../EventerRoutes.ts";

export default function SettingsPage() {

    const account = useAccount()

    const [openDeleteAccountConfirmationDialog, setOpenDeleteAccountConfirmationDialog] = useState(false)

    const handleDeleteAccountClick = useCallback(() => {
        setOpenDeleteAccountConfirmationDialog(true)
    }, [setOpenDeleteAccountConfirmationDialog])

    const handleCloseDeleteAccountComfirmationDialog = useCallback(async (yes: boolean) => {
        if (yes) {
            let accountId = account.id
            if (accountId) {
                await eventerConsoleApiClient.deleteAccount({accountId: accountId})

                // Account has been deleted => full reload
                window.location.href = EventerRoutes.path()
            }
        }
        setOpenDeleteAccountConfirmationDialog(false)
    }, [account, setOpenDeleteAccountConfirmationDialog])

    return (
        <>
            <Box
                sx={{
                    // height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '2rem',
                    // justifyContent: 'center',
                    // backgroundColor: '#000',
                }}
            >
                <List>
                    <ListItem onClick={handleDeleteAccountClick} sx={{cursor: "pointer"}}>
                        <ListItemIcon
                            sx={{minWidth: `32px`}}
                        >
                            <DeleteForeverIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Account löschen"/>
                    </ListItem>
                </List>
                <DeleteAccountConfirmationDialog
                    account={account}
                    open={openDeleteAccountConfirmationDialog}
                    onClose={handleCloseDeleteAccountComfirmationDialog}
                />
            </Box>
        </>
    )
}
