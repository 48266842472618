import {ReactNode, StrictMode} from "react";
import {CssBaseline} from "@mui/material";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {de} from "date-fns/locale/de";
import {BrowserRouter} from "react-router-dom";
import {SessionProvider} from "./authentication/SessionProvider";


// TODO: log errors?
// bind error logging at first
// window.onerror = (e: any) => {
//     logError(e, 'console.eventer.app/index.tsx')
// }
// window.onunhandledrejection = (e: any) => {
//     logError(e, 'console.eventer.app/index.tsx')
// }


const queryClient = new QueryClient()

interface RootElementWrapperProps {
    readonly children?: ReactNode;
}

export default function RootElementWrapper(props: RootElementWrapperProps) {
    return (
        <>
            <StrictMode>
                <CssBaseline/>
                <QueryClientProvider client={queryClient}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
                        <BrowserRouter>
                            <SessionProvider>
                                {props.children}
                            </SessionProvider>
                        </BrowserRouter>
                    </LocalizationProvider>
                </QueryClientProvider>
            </StrictMode>
        </>
    )
}
