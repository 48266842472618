import {ApiClient, HttpMethod} from "../../appfw/api-client/ApiClient.ts";


export interface DiscoverResponse {
    readonly accounts: Account[];
}

export interface Account {
    readonly id: string;
    readonly podName: string;
    readonly emailContacts?: EmailContact[];
}

export interface GooglePlace {
    readonly id: string;
    readonly name: string;
    readonly address: string;
}

export interface IntegrationSyncInfo {
    readonly is_syncing: boolean;
    readonly last_sync_finished_at?: string;
}

export interface GCalIntegration {
    readonly id: string
    readonly google_calendar_id: string
    readonly google_calendar_hash: string
    readonly google_calendar_name: string
    readonly default_google_place_id?: string
    readonly trusted: boolean
    readonly sync_info: IntegrationSyncInfo
}

export interface EmailContact {
    readonly email: string
    readonly name?: string
}

export interface Email {
    readonly to: EmailContact[];
    readonly subject: string;
    readonly message: string;
}

export interface InfoEmailRequest {
    readonly email_addresses: string[];
    readonly starts_at_min?: Date;
    readonly starts_at_max?: Date;
    readonly updated_since?: Date;
}

class EventerConsoleApiClient extends ApiClient {

    constructor() {
        super(process.env.NODE_ENV === 'production' ? 'https://api.eventer.app/v1/console/' : 'http://localhost:8888/v1/console/')
    }

    async discover() {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<DiscoverResponse>(
            `accounts/discover`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async createAccount(params: { podName: string; }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Account>(
            `accounts`,
            {
                sessionId: sessionId,
                params: {
                    podName: params.podName,
                }
            },
        )
    }

    async deleteAccount(params: {
        accountId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}`,
            {
                method: HttpMethod.DELETE,
                sessionId: sessionId,
            },
        )
    }

    async searchPlaces(
        params: { q: string; }
    ): Promise<GooglePlace[]> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GooglePlace[]>(
            `places/search?q=${params.q}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async testGCalIntegration(params: {
        accountId: string;
        gCalId: string;
        defaultGooglePlaceId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}/integrations/gcal/test`,
            {
                sessionId: sessionId,
                params: {
                    "google_calendar_id": params.gCalId,
                    "default_google_place_id": params.defaultGooglePlaceId,
                },
            },
        )
    }

    async getGCalIntegrations(params: {
        accountId: string;
    }): Promise<GCalIntegration[]> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration[]>(
            `accounts/${params.accountId}/integrations/gcal`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async getGCalIntegration(params: {
        accountId: string;
        id: string;
    }): Promise<null | GCalIntegration> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.id}`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async addGCalIntegration(params: {
        accountId: string;
        gCalId: string;
        defaultGooglePlaceId: string;
    }): Promise<GCalIntegration> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal`,
            {
                sessionId: sessionId,
                params: {
                    "google_calendar_id": params.gCalId,
                    "default_google_place_id": params.defaultGooglePlaceId,
                },
            },
        )
    }

    async syncGCalIntegration(params: {
        accountId: string;
        gCalId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalId}/sync`,
            {
                sessionId: sessionId,
            },
        )
    }

    async deleteGCalIntegration(params: {
        accountId: string;
        gCalId: string;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<GCalIntegration>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalId}`,
            {
                method: HttpMethod.DELETE,
                sessionId: sessionId,
            },
        )
    }

    async addEmailContacts(params: {
        accountId: string;
        emailContacts: EmailContact[];
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Account>(
            `accounts/${params.accountId}/add_email_contacts`,
            {
                sessionId: sessionId,
                params: {
                    email_contacts: params.emailContacts,
                }
            },
        )
    }

    async removeEmailContacts(params: {
        accountId: string;
        emailAddresses: String[];
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Account>(
            `accounts/${params.accountId}/remove_email_contacts`,
            {
                sessionId: sessionId,
                params: {
                    email_addresses: params.emailAddresses,
                }
            },
        )
    }

    async testInfoEmail(params: {
        accountId: string;
        gCalIntegrationId: string;
        infoEmailRequest: InfoEmailRequest;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<Email>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalIntegrationId}/mails/test-info-email`,
            {
                sessionId: sessionId,
                params: params.infoEmailRequest,
            },
        )
    }

    async sendInfoEmail(params: {
        accountId: string;
        gCalIntegrationId: string;
        infoEmailRequest: InfoEmailRequest;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `accounts/${params.accountId}/integrations/gcal/${params.gCalIntegrationId}/mails/send-info-email`,
            {
                sessionId: sessionId,
                params: params.infoEmailRequest,
            },
        )
    }
}


const eventerConsoleApiClient = new EventerConsoleApiClient();
export {eventerConsoleApiClient};
