import {ApiClient, HttpMethod} from "./ApiClient";

export interface NotificationsConfig {
    readonly enabled: boolean;
}

export interface User {
    readonly id: string
    readonly name?: string
    readonly avatarUrl?: string
}

export interface Credentials {
    readonly sessionId: string;
    readonly user: User;
}

class AppFwApiClient extends ApiClient {

    constructor() {
        super(process.env.NODE_ENV === 'production' ? 'https://api.eventer.app/v1/appfw/' : 'http://localhost:8888/v1/appfw/')
        // super('https://api.eventer.app/v1/appfw/')
    }

    startOidcLoginUrl(app: string, provider: string): string {
        return `${this.baseUrl}auth/oidc/${app}/${provider}/start`
    }

    async sessionAuth(
        params: { sessionId: string; },
    ): Promise<Credentials> {
        return this.fetch<Credentials>(`auth/session`, {
            params: {sessionId: params.sessionId}
        })
    }

    async logout(params: { sessionId: string; }) {
        return this.fetch<undefined>('auth/logout', {sessionId: params.sessionId})
    }

    async getNotificationsConfig(): Promise<NotificationsConfig> {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<NotificationsConfig>(
            `notifications/config`,
            {
                sessionId: sessionId,
                method: HttpMethod.GET,
            },
        )
    }

    async updateNotificationsConfig(params: { enabled: boolean }) {
        let sessionId = localStorage.getItem('sessionId')
        return this.fetch<undefined>(
            `notifications/config`,
            {
                sessionId: sessionId,
                method: HttpMethod.PATCH,
                params: params,
            },
        )
    }

    async updateWebPushSubscription(params: {
        installationId: string;
        subscription: PushSubscription | null;
    }) {
        let sessionId = localStorage.getItem('sessionId')
        let payload = undefined
        if (params.subscription) {
            payload = params.subscription.toJSON()
        }
        return this.fetch<undefined>(
            `notifications/webpush/${params.installationId}`,
            {
                sessionId: sessionId,
                method: HttpMethod.PUT,
                params: payload,
            },
        )
    }


    // ???

    // async getUser(params: { id: string; }): Promise<User> {
    //     let sessionId = localStorage.getItem('sessionId')
    //     return this.fetch<User>(
    //         `users/${params.id}`,
    //         {
    //             sessionId: sessionId,
    //             method: HttpMethod.GET,
    //         },
    //     )
    // }
}

const appFwApiClient = new AppFwApiClient();
export {appFwApiClient};
