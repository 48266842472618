import {useLocation, useNavigate} from "react-router-dom";
import {ReactNode, useContext, useEffect} from "react"
import Session from "./Session";
import {SessionContext} from "./SessionProvider";
import SplashScreen from "../ui/splash/SplashScreen";

export interface AuthenticationConfig {
    readonly app: string;
    readonly oidcProviders: string[];
}

interface AuthenticationGuardProps {
    readonly authenticationConfig: AuthenticationConfig;
    readonly splashScreenOutlet: ReactNode;
    readonly children: ReactNode;
}

export default function AuthenticationGuard(props: AuthenticationGuardProps) {

    const location = useLocation()
    const navigate = useNavigate()

    const session = useContext<undefined | null | Session>(SessionContext)

    useEffect(() => {
        if (session === null) {
            console.log("Session initialized, but not logged in ==> redirect to login page")

            let path: null | string = location.pathname
            if (path && path !== "/") {
                if (path.startsWith("/")) {
                    path = path.substring(1)
                }
            } else {
                path = null
            }

            // TODO: login page?
            if (path) {
                navigate(`/?path=${path}`)
            } else {
                navigate(`/`)
            }
            return
        }
    }, [session, location.pathname, navigate])

    if (session === undefined) {
        // still initializing
        return null
    }

    if (session === null) {
        console.log("location.pathname", location.pathname)
        return (
            <SplashScreen authenticationConfig={props.authenticationConfig}>
                {props.splashScreenOutlet}
            </SplashScreen>
        )
    }

    return (
        <>
            {props.children}
        </>
    )
}