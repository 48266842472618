import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useAccount} from "./Accounts.tsx";
import EventerRoutes from "../../../../EventerRoutes.ts";
import {useEffect} from "react";

export default function AccountGuard() {

    const account = useAccount()

    const location = useLocation()
    const navigate = useNavigate()

    useEffect(() => {
        const accountPath = EventerRoutes.accounts.account.path(account.id)
        if (location.pathname.trim() == accountPath || location.pathname.trim() == `${accountPath}/`) {

            // We are at "/accounts/:id" without being on a subroute, route to "/accounts/:id/importers"
            let accountDefaultPath = EventerRoutes.accounts.account.importers.path(account.id)

            console.log(`We are at '${location.pathname.trim()}' without being on a subroute, route to: `, accountDefaultPath)

            navigate(accountDefaultPath, {replace: true})
            return
        }
    }, [location.pathname, account.id, navigate])

    return <Outlet/>
}