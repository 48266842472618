import React, { createContext, useContext, useState, useCallback, useEffect } from "react";
import { Account, eventerConsoleApiClient } from "../../../api-client/EventerConsoleApiClient.ts";
import {useParams} from "react-router-dom";

interface AccountsContextProps {
    accounts: undefined | Account[];
    refresh: () => Promise<void>;
}

const AccountsContext = createContext<AccountsContextProps | undefined>(undefined);

export function AccountsProvider({ children }: { children: React.ReactNode }) {
    const [accounts, setAccounts] = useState<undefined | Account[]>(undefined);

    const refresh = useCallback(async () => {
        console.log("Refreshing accounts...");
        let discoverResponse = await eventerConsoleApiClient.discover();
        console.log("Neue Accounts:", discoverResponse.accounts);
        setAccounts([...discoverResponse.accounts]); // Neue Referenz setzen
    }, [setAccounts])

    useEffect(() => {
        refresh(); // Initial Accounts laden
    }, [refresh, setAccounts])

    return (
        <AccountsContext.Provider value={{ accounts, refresh }}>
            {children}
        </AccountsContext.Provider>
    );
}

export function useAccounts() {
    const context = useContext(AccountsContext);
    if (!context) {
        throw new Error("useAccounts must be used within an AccountsProvider");
    }
    return context;
}

export function useAccount() {
    const context = useContext(AccountsContext);
    const {accountId} = useParams()
    if (!context) {
        throw new Error("useAccounts must be used within an AccountsProvider");
    }
    let account = context.accounts?.find(account => account.id === accountId)
    if (!account) {
        // TODO?
        throw new Error(`Invalid accountId: '${accountId}'`)
    }
    return account;
}
