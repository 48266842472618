import {WorkerRequest, WorkerResponse} from "../shared/worker-types.ts";
import {EventerPodContract} from "../shared/eventer-pod.ts";

const worker = new Worker(new URL('../worker/eventer-worker.ts', import.meta.url), {
    type: 'module',
})

let messageCounter = 0
const pending = new Map<string, (res: WorkerResponse) => void>()

worker.onmessage = (event: MessageEvent<WorkerResponse>) => {
    const { messageId } = event.data
    console.log('[client] Received', messageId, event.data)
    const cb = pending.get(messageId)
    if (cb) {
        cb(event.data)
        pending.delete(messageId)
    }
}

function call<K extends keyof EventerPodContract>(
    type: K,
    payload: Parameters<EventerPodContract[K]>[0]
): Promise<Awaited<ReturnType<EventerPodContract[K]>>> {
    const messageId = `msg-${type}-${messageCounter++}`
    return new Promise((resolve, reject) => {
        pending.set(messageId, (response) => {
            if (response.error) {
                reject(new Error(response.error))
            } else {
                resolve(response.payload)
            }
        })
        const request: WorkerRequest = { type, payload, messageId } as any
        worker.postMessage(request)
    })
}

export const eventerPod: EventerPodContract = {
    init: async () => await call('init', undefined),
    loadPodEvents: async (params) => await call('loadPodEvents', params),
    getResource: async (params) => await call('getResource', params),
}