import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    Typography,
} from "@mui/material";
import {eventerConsoleApiClient, GCalIntegration, GooglePlace} from "../../../../../api-client/EventerConsoleApiClient.ts";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {OutlinedInputProps} from "@mui/material/OutlinedInput";

export interface AddGCalIntegrationDialogProps {
    accountId: string;
    open: boolean;
    onClose: (gCalIntegration?: GCalIntegration) => void;
}

function AddGCalIntegrationDialog(props: AddGCalIntegrationDialogProps) {

    const [gCalId, setGCalId] = useState("")
    const [value, setValue] = React.useState<GooglePlace | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<readonly GooglePlace[]>([]);

    const handleGCalValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError("")
        setGCalId(e.target.value)
    }

    const [error, setError] = useState("")

    const handleClose = useCallback((gCalIntegration?: GCalIntegration) => {
        setError("")
        props.onClose(gCalIntegration)
    }, [props])

    const handleTest = useCallback(async () => {
        try {
            await eventerConsoleApiClient.testGCalIntegration({
                accountId: props.accountId,
                gCalId: gCalId,
                defaultGooglePlaceId: value?.id || "",
            })
        } catch (e) {
            console.log(e)
            // TODO: more central error handling
            const errorMsg = await (e as Response).text()
            setError(errorMsg)
        }
    }, [gCalId, props.accountId, value?.id])

    const handleAdd = useCallback(async () => {
        try {
            const gCalIntegration = await eventerConsoleApiClient.addGCalIntegration({
                accountId: props.accountId,
                gCalId: gCalId,
                defaultGooglePlaceId: value?.id || "",
            })
            handleClose(gCalIntegration);
        } catch (e) {
            console.log(e)
            // TODO: more central error handling
            const errorMsg = await (e as Response).text()
            setError(errorMsg)
        }
    }, [gCalId, handleClose, props.accountId, value?.id])


    const cachedPlaces = useRef<Map<string, Array<GooglePlace>>>(new Map<string, Array<GooglePlace>>())
    const getGooglePlaces = useCallback(async () => {
        console.log("fetchPlaces: ", inputValue)

        if (!inputValue || inputValue.trim().length === 0) {
            return [];
        }

        let cached = cachedPlaces.current.get(inputValue)
        if (cached) {
            return cached
        }

        const fetchedPlaces = await eventerConsoleApiClient.searchPlaces({q: inputValue})
        cachedPlaces.current.set(inputValue, fetchedPlaces)
        return fetchedPlaces
    }, [inputValue])

    useEffect(() => {
        let active = true;
        getGooglePlaces()
            .then((places) => {
                if (active) {
                    let newOptions: readonly GooglePlace[] = [];
                    if (value) {
                        newOptions = [value];
                    }
                    if (places && places.length > 0) {
                        newOptions = [...newOptions, ...places];
                    }
                    setOptions(newOptions);
                }
            })
        return () => {
            active = false;
        };
    }, [value, inputValue, getGooglePlaces]);

    return (
        <Dialog onClose={() => handleClose()} open={props.open}>
            <DialogTitle>Google Kalender verbinden</DialogTitle>
            <DialogContent>
                <DialogContentText
                    margin="normal"
                >
                    Eventer-Console muss Leserechte auf den Kalender besitzen.
                    Hierfür musst Du den Kalender für folgende E-Mail-Adresse freigeben:
                    <Typography
                        margin="normal"
                        variant="caption"
                        display="block"
                        gutterBottom
                    >
                        eventer-console-backend@eventer-console-406916.iam.gserviceaccount.com
                    </Typography>
                </DialogContentText>
                <TextField
                    autoFocus
                    id="gcal-id"
                    label="Google Kalender ID"
                    type="text"
                    fullWidth
                    variant="standard"
                    onChange={handleGCalValueChange}
                />

                <Autocomplete
                    getOptionLabel={(option) => `${option.name} (${option.address})`}
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={value}
                    noOptionsText="Unbekannt"
                    onChange={(_: any, newValue: GooglePlace | null) => {
                        console.log("newValue: ", newValue)
                        setOptions(newValue ? [newValue, ...options] : options)
                        setValue(newValue)
                    }}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                    }}

                    // I have no clue, why this patch is necessary
                    // renderInput={(params) => <TextField {...params} label="Standard Veranstaltungsort"/>}
                    renderInput={(params) =>
                        <TextField
                            id={params.id}
                            disabled={params.disabled}
                            fullWidth={params.fullWidth}
                            InputLabelProps={params.InputLabelProps}
                            inputProps={params.inputProps}
                            InputProps={params.InputProps as OutlinedInputProps}
                            label="Standard Veranstaltungsort"
                        />
                    }

                    renderOption={(props, googlePlace) => {
                        return (
                            <Typography {...props} key={googlePlace.id} variant="body2">
                                {googlePlace.name} ({googlePlace.address})
                            </Typography>
                        )
                    }}
                    sx={{
                        marginTop: 2,
                    }}
                >
                </Autocomplete>
                <Typography
                    margin="normal"
                    display="block"
                    gutterBottom
                >
                    {error}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose()}>Abbrechen</Button>
                <Button onClick={handleTest}>Test</Button>
                <Button onClick={handleAdd}>Hinzufügen</Button>
            </DialogActions>
        </Dialog>
    )
}

export default AddGCalIntegrationDialog;
