import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    FormLabel,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import React, {useCallback, useEffect, useMemo} from "react";
import {Email, eventerConsoleApiClient, GCalIntegration, InfoEmailRequest} from "../../../../../api-client/EventerConsoleApiClient.ts";
import DeleteIcon from "@mui/icons-material/Delete";
import {SendInfoMailConfirmationDialog} from "./SendInfoMailConfirmationDialog.tsx";

export interface SendInfoMailDialogProps {
    accountId: string;
    cGalIntegration: GCalIntegration;
    open: boolean;
    onClose: () => void;
}

const validateRequest = function (sendType: "info" | "updates", request: InfoEmailRequest): InfoEmailRequest {
    if (sendType === "info") {
        return {
            ...request,
            starts_at_min: request.starts_at_min,
            starts_at_max: request.starts_at_max,
            updated_since: undefined,
        }
    } else {
        return {
            ...request,
            updated_since: request.updated_since,
            starts_at_min: undefined,
            starts_at_max: undefined,
        }
    }
}

export function SendInfoMailDialog(props: SendInfoMailDialogProps) {

    const [sendActive, setSendActive] = React.useState(false)

    const [sendType, setSendType] = React.useState<"info" | "updates">("info")

    const [openSendHint, setOpenSendHint] = React.useState(false)

    const [emailAddresses, setEmailAddresses] = React.useState<string[]>([])

    const [addEmailAddressValue, setAddEmailAddressValue] = React.useState("")

    const [invalidNewEmailAddress, setInvalidNewEmailAddress] = React.useState<boolean>(false)

    const [sendInfoEmailRequest, setSendInfoEmailRequest] =
        React.useState<InfoEmailRequest>(
            {
                email_addresses: [],
                starts_at_min: new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    1,
                    0, 0, 0, 0,
                ),
            }
        )

    const [sendMailConfirmationEmail, setSendMailConfirmationEmail] = React.useState<Email>()

    const send = useCallback(async () => {
        let validatedRequest = validateRequest(sendType, sendInfoEmailRequest)
        await eventerConsoleApiClient.sendInfoEmail({
            accountId: props.accountId,
            gCalIntegrationId: props.cGalIntegration.id,
            infoEmailRequest: validatedRequest,
        })
        setOpenSendHint(true)
        props.onClose()
    }, [props, sendInfoEmailRequest, sendType])

    const handleSendButtonClick = useCallback(async () => {
        let validatedRequest = validateRequest(sendType, sendInfoEmailRequest)
        let email = await eventerConsoleApiClient.testInfoEmail({
            accountId: props.accountId,
            gCalIntegrationId: props.cGalIntegration.id,
            infoEmailRequest: validatedRequest,
        })
        setSendMailConfirmationEmail(email)

    }, [props.accountId, props.cGalIntegration.id, sendInfoEmailRequest, sendType])

    const handleStartsAtMinChanged = useCallback((date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            date.setDate(1)
            date.setHours(0, 0, 0, 0)
            setSendInfoEmailRequest({...sendInfoEmailRequest, starts_at_min: date,})
        } else {
            setSendInfoEmailRequest({...sendInfoEmailRequest, starts_at_min: undefined,})
        }
    }, [sendInfoEmailRequest, setSendInfoEmailRequest])

    const handleStartsAtMaxChanged = useCallback((date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            let d = new Date(date.getFullYear(), date.getMonth() + 1, 0)
            d.setHours(23, 59, 59, 0)
            setSendInfoEmailRequest({...sendInfoEmailRequest, starts_at_max: d,})
        } else {
            setSendInfoEmailRequest({...sendInfoEmailRequest, starts_at_max: undefined,})
        }
    }, [sendInfoEmailRequest, setSendInfoEmailRequest])

    const handleUpdatedSinceChanged = useCallback((date: Date | null) => {
        if (date && !isNaN(date.getTime())) {
            date.setHours(0, 0, 0, 0)
            setSendInfoEmailRequest({...sendInfoEmailRequest, updated_since: date,})
        } else {
            setSendInfoEmailRequest({...sendInfoEmailRequest, updated_since: undefined,})
        }
    }, [sendInfoEmailRequest, setSendInfoEmailRequest])

    const onAddEmailAddressValueChanged = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setAddEmailAddressValue(e.target.value)
        setInvalidNewEmailAddress(false)
    }, [
        setAddEmailAddressValue,
        setInvalidNewEmailAddress,
    ])

    const onAddEmailAddressKeyUp = useCallback(async (e: { key: string; }) => {
        if (e.key === "Enter") {
            try {
                await eventerConsoleApiClient.addEmailContacts({
                    accountId: props.accountId,
                    emailContacts: [{email: addEmailAddressValue}],
                })
            } catch (e) {
                setInvalidNewEmailAddress(true)
                return
            }

            setEmailAddresses([...emailAddresses, addEmailAddressValue])
            setSendInfoEmailRequest({
                ...sendInfoEmailRequest,
                email_addresses: [...sendInfoEmailRequest.email_addresses, addEmailAddressValue],
            })
            setAddEmailAddressValue("")
        }
    }, [
        addEmailAddressValue,
        emailAddresses,
        props.accountId,
        sendInfoEmailRequest,
        setAddEmailAddressValue,
        setEmailAddresses,
        setInvalidNewEmailAddress,
        setSendInfoEmailRequest,
    ])

    const deleteEmailAddress = useCallback((email: string) => {
        let active = true

        eventerConsoleApiClient.removeEmailContacts({
            accountId: props.accountId,
            emailAddresses: [email],
        }).then(() => {
            if (!active) {
                return
            }
            setSendInfoEmailRequest({
                ...sendInfoEmailRequest,
                email_addresses: sendInfoEmailRequest.email_addresses.filter((it) => it !== email)
            })
            setEmailAddresses(emailAddresses.filter((it) => it !== email))
        })

        return () => {
            active = false
        }
    }, [
        emailAddresses,
        props.accountId,
        sendInfoEmailRequest,
        setSendInfoEmailRequest,
    ])

    const emailControls = useMemo(() => {
        return emailAddresses.map((email) => {
            let checked = sendInfoEmailRequest.email_addresses.includes(email)
            return (
                <Stack key={email} direction="row">
                    <FormControlLabel
                        control={<Checkbox
                            checked={checked}
                            onChange={(_event, checked) => {
                                if (checked) {
                                    setSendInfoEmailRequest({
                                        ...sendInfoEmailRequest,
                                        email_addresses: [...sendInfoEmailRequest.email_addresses, email],
                                    })
                                } else {
                                    setSendInfoEmailRequest({
                                        ...sendInfoEmailRequest,
                                        email_addresses: sendInfoEmailRequest.email_addresses.filter((it) => it !== email)
                                    })
                                }
                            }}
                        />}
                        label={email}
                    />
                    <IconButton onClick={() => {
                        deleteEmailAddress(email)
                    }}>
                        <DeleteIcon/>
                    </IconButton>
                </Stack>
            )
        })
    }, [deleteEmailAddress, emailAddresses, sendInfoEmailRequest, setSendInfoEmailRequest])

    useEffect(() => {
            let active = true

            eventerConsoleApiClient.addEmailContacts({
                    accountId: props.accountId,
                    emailContacts: [],
                }
            ).then((account) => {
                if (!active) {
                    return
                }
                let newEmailAddresses = account.emailContacts
                    ?.map((emailContact) => emailContact.email)
                if (newEmailAddresses) {
                    setEmailAddresses(newEmailAddresses)
                    let missing = newEmailAddresses.filter((it) => !sendInfoEmailRequest.email_addresses.includes(it))
                    if (missing.length > 0) {
                        setSendInfoEmailRequest({
                            ...sendInfoEmailRequest,
                            email_addresses: [...sendInfoEmailRequest.email_addresses, ...missing],
                        })
                    }

                } else {
                    setEmailAddresses([])
                }
            })

            return () => {
                active = false
            }
        },
        // We don't want "endless" calls & updates
        // eslint-disable-next-line
        [props.accountId]
    )

    const handleTypeChange = useCallback((_event: React.MouseEvent<HTMLElement>, nextView: string) => {
        setSendType(nextView as "info" | "updates")
    }, [setSendType])

    useEffect(() => {
        if (sendInfoEmailRequest.email_addresses.length === 0) {
            setSendActive(false)
            return
        }
        if (sendType === "info") {
            if (sendInfoEmailRequest.starts_at_min === undefined || sendInfoEmailRequest.starts_at_max === undefined) {
                setSendActive(false)
                return
            }
        } else {
            if (sendInfoEmailRequest.updated_since === undefined) {
                setSendActive(false)
                return
            }
        }
        setSendActive(true)
    }, [sendInfoEmailRequest, sendType, setSendActive])

    let timeRangeEl
    if (sendType === "info") {
        timeRangeEl = (
            <Stack direction="row" spacing={2} sx={{marginTop: "20px"}}>
                <DatePicker
                    value={sendInfoEmailRequest.starts_at_min || null}
                    label={"Startmonat"}
                    views={['month', 'year']}
                    openTo="month"
                    minDate={new Date(new Date().setMonth(new Date().getMonth() - 1))}
                    maxDate={sendInfoEmailRequest.starts_at_max}
                    onChange={handleStartsAtMinChanged}
                />
                <DatePicker
                    value={sendInfoEmailRequest.starts_at_max || null}
                    label={"Endmonat"}
                    views={['month', 'year']}
                    openTo="month"
                    minDate={sendInfoEmailRequest.starts_at_min}
                    onChange={handleStartsAtMaxChanged}
                />
            </Stack>
        )
    } else {
        timeRangeEl = (
            <Stack direction="row" spacing={2} sx={{marginTop: "20px"}}>
                <DatePicker
                    value={sendInfoEmailRequest.updated_since || null}
                    label={"Änderungen seit"}
                    views={['day', 'month']}
                    maxDate={new Date()}
                    onChange={handleUpdatedSinceChanged}
                />
            </Stack>
        )
    }

    return (
        <>
            <Snackbar
                open={openSendHint}
                autoHideDuration={5000}
                onClose={() => {
                    setOpenSendHint(false)
                }}
                message={`E-Mail an ${sendInfoEmailRequest.email_addresses.length} Empfänger verschickt.`}
            />
            <SendInfoMailConfirmationDialog
                accountId={props.accountId}
                gCalIntegration={props.cGalIntegration}
                infoEmail={sendMailConfirmationEmail}
                onClose={(yes: boolean) => {
                    if (yes) {
                        // TODO: error handling?
                        send().then(() => {
                            setSendMailConfirmationEmail(undefined)
                        })
                    } else {
                        setSendMailConfirmationEmail(undefined)
                    }
                }}
            />
            <Dialog
                open={props.open}
                fullWidth={true}
            >
                <DialogTitle>E-Mail senden</DialogTitle>
                <DialogContent>

                    <ToggleButtonGroup
                        color="primary"
                        value={sendType}
                        exclusive
                        onChange={handleTypeChange}
                        // aria-label="Platform"
                        sx={{marginTop: "10px"}}
                    >
                        <ToggleButton value="info">Ankündigung</ToggleButton>
                        <ToggleButton value="updates">Änderung</ToggleButton>
                    </ToggleButtonGroup>

                    {timeRangeEl}

                    <FormGroup sx={{marginTop: "20px"}}>
                        <FormLabel>Empfänger</FormLabel>
                        {emailControls}
                    </FormGroup>

                    <TextField
                        label="Hinzufügen"
                        variant="standard"
                        error={invalidNewEmailAddress}
                        helperText={invalidNewEmailAddress ? "ungültige E-Mail" : ""}
                        onChange={onAddEmailAddressValueChanged}
                        inputProps={
                            onkeydown = onAddEmailAddressKeyUp
                        }
                        value={addEmailAddressValue}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClose}>Abbrechen</Button>
                    <Button onClick={handleSendButtonClick} disabled={!sendActive}>Senden (Vorschau)</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
