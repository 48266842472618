import {Box, Button, Container, Stack, TextField, Typography} from "@mui/material";
import React, {useCallback, useState} from "react";
import {eventerConsoleApiClient} from "../../../../api-client/EventerConsoleApiClient.ts"
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import {ErrorResponse} from "../../../../../appfw/api-client/ApiClient.ts";
import {useAccounts} from "../Accounts.tsx";
import {useNavigate} from "react-router-dom";
import EventerRoutes from "../../../../../EventerRoutes.ts";

const podNameRegex = /^[a-zA-Z0-9]+(-[a-zA-Z0-9]+)*$/;

export default function NewAccountPage() {

    const navigate = useNavigate()
    const {refresh} = useAccounts()

    const [name, setName] = useState<string>("")

    const [tooShort, setTooShort] = useState<boolean>(true)
    const [tooLong, setTooLong] = useState<boolean>(false)
    const [correctPattern, setCorrectPattern] = useState<boolean>(true)

    const [validName, setValidName] = useState<boolean>(false)

    const [errorResponse, setErrorResponse] = useState<ErrorResponse | undefined>()

    const handleNameValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void =
        useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
            setErrorResponse(undefined)

            let value = e.target.value.toLowerCase()

            if (e.target.value != value) {
                // enforce lowercase
                e.target.value = value
            }

            setName(e.target.value)

            let _tooShort = value.length < 4
            setTooShort(_tooShort)

            let _tooLong = value.length > 24
            setTooLong(_tooLong)

            let _correctPattern = value.length == 0 || podNameRegex.test(value)
            setCorrectPattern(_correctPattern)

            setValidName(!_tooShort && !_tooLong && _correctPattern)

        }, [setErrorResponse, setTooShort, setTooLong, setCorrectPattern, setValidName])

    const handleCreateClick = useCallback(async () => {
        let active = true

        try {
            let account = await eventerConsoleApiClient.createAccount({podName: name})

            await refresh()
            navigate(EventerRoutes.accounts.account.path(account.id))

        } catch (e) {
            console.log(e)
            let _errorResponse = await eventerConsoleApiClient.parseErrorResponse(e as Response)
            if (active) {
                setErrorResponse(_errorResponse)
            }
        }

        return () => active = false
    }, [name, setErrorResponse, refresh])

    const handleKeyDown = useCallback(async (e: { key: string; }) => {
        if (e.key === 'Enter' && validName) {
            await handleCreateClick()
        }
    }, [handleCreateClick, validName])

    let helperText = null
    if (errorResponse) {
        helperText = errorResponse.errors?.map((e) => {
            if (e.message) {
                return `${e.message}`
            } else {
                return `Fehlercode: '${e.code}'`
            }
        }).join()
    }

    let tooShortIcon;
    if (tooShort) {
        tooShortIcon = <ErrorOutlineIcon fontSize="small" sx={{color: "error.main"}}/>
    } else {
        tooShortIcon = <CheckIcon fontSize="small" sx={{color: "success.main"}}/>
    }

    let tooLongIcon;
    if (tooLong) {
        tooLongIcon = <ErrorOutlineIcon fontSize="small" sx={{color: "error.main"}}/>
    } else {
        tooLongIcon = <CheckIcon fontSize="small" sx={{color: "success.main"}}/>
    }

    let correctPatternIcon;
    if (correctPattern) {
        correctPatternIcon = <CheckIcon fontSize="small" sx={{color: "success.main"}}/>
    } else {
        correctPatternIcon = <ErrorOutlineIcon fontSize="small" sx={{color: "error.main"}}/>
    }

    return (
        <Container
            maxWidth="sm"
            sx={{
                marginTop: 8,
                paddingLeft: 5,
                paddingRight: 5,
            }}
        >

            <Typography
                variant="h6"
                sx={{
                    marginTop: 7,
                    textAlign: "center",
                }}
            >
                Erstelle einen neuen Account
            </Typography>

            <TextField
                autoFocus
                id="account-name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"

                error={helperText != null}
                helperText={helperText}

                onChange={handleNameValueChange}
                inputProps={
                    onkeydown = handleKeyDown
                }
                sx={{
                    marginTop: 4,
                }}
            />

            <Stack spacing={.5} sx={{paddingLeft: 1, paddingTop: 1}}>

                <Stack direction="row" spacing={1}>
                    {correctPatternIcon}
                    <Typography variant="caption" component="div" sx={{marginTop: "1px"}}>
                        nur Buchstaben, Zahlen oder Bindestrich
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1} sx={{marginTop: "1px"}}>
                    {tooShortIcon}
                    <Typography variant="caption" component="div">
                        mindestens 4 Zeichen
                    </Typography>
                </Stack>

                <Stack direction="row" spacing={1} sx={{marginTop: "1px"}}>
                    {tooLongIcon}
                    <Typography variant="caption" component="div">
                        maximal 24 Zeichen
                    </Typography>
                </Stack>
            </Stack>

            <Box sx={{marginTop: 5, paddingRight: 2, textAlign: "right"}}>
                <Button
                    onClick={handleCreateClick}
                    variant="contained"
                    disabled={!validName}
                >
                    Erstellen
                </Button>
            </Box>
        </Container>
    )
}
