import {Box, Typography} from "@mui/material";

export default function SplashScreenOutlet() {

    return (
        <>
            <Box
                component="img"
                src="/icons/favicon.svg"
                alt="Logo"
                sx={{
                    width: '100px',
                    marginBottom: '0.5rem',
                }}
            />
            <Typography variant="h5" sx={{marginBottom: '0.5rem'}}>
                Willkommen bei Eventer
            </Typography>
            <Typography variant="body1" sx={{marginBottom: '6rem'}}>
                Deine Plattform für Veranstaltungen
            </Typography>
            <Typography variant="body1" sx={{marginBottom: '2rem', color: 'text.secondary'}}>
                Erstellen - Verwalten - Verteilen - Vernetzen
            </Typography>
        </>
    )
}
