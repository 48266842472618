import {Card, CardActionArea, CardContent, CardHeader, CardMedia, Typography, useTheme} from "@mui/material"
import {ResourceWrapper} from "../../../../../eventer-pod/client/ResourceWrapper.ts";
import {formatDateTime} from "@eventer/api-client/lib/esm/utils/datetime-util";
import {useContext, useEffect, useState} from "react";
import {eventerPod} from "../../../../../eventer-pod/client/eventer-pod-client.ts";
import Session from "../../../../../appfw/authentication/Session.ts";
import {SessionContext} from "../../../../../appfw/authentication/SessionProvider.tsx";
import Semantic from "../../../../../eventer/Semantic.ts";

interface EventCardProps {
    event: ResourceWrapper
}

export function EventCard({ event }: EventCardProps) {

    const session = useContext<undefined | null | Session>(SessionContext)
    const [image, setImage] = useState<undefined | null | ResourceWrapper>(undefined)

    const theme = useTheme()

    useEffect(() => {

        const sessionId = session?.id
        if (sessionId == undefined) {
            console.log("sessionId is undefined")
            return;
        }

        let imageUri = event.getFirstString("https://schema.org/image")
        if (!imageUri) {
            return
        }

        eventerPod.init()
            .then(() => {
                eventerPod.getResource({uri: imageUri, sessionId: sessionId}).then((resource) => {
                    console.log("image", resource)
                    if (resource == null) {
                        setImage(null)
                    } else {
                        setImage(new ResourceWrapper(resource))
                    }
                })
            })
    }, [event, session])

    let title = event.getFirstString("https://schema.org/name") ?? "(ohne Titel)"
    const description = event.getFirstString("https://schema.org/description")
    const startDate = event.getFirstString("https://schema.org/startDate")
    const parsedDate = startDate ? new Date(startDate) : null
    let dateText = parsedDate ? formatDateTime({date: parsedDate}) : undefined

    const eventStatus = event.getFirstString(Semantic.Predicates.eventStatus.uri)

    let bgColor = undefined
    if (eventStatus !== Semantic.Predicates.eventStatus.eventScheduled) {
        dateText = `[nicht öffentlich] ${dateText}`
        bgColor = theme.palette.secondary.dark
    }

    let mediaEl = undefined
    if (image) {
        const imageContentUrl = image.getFirstString("https://schema.org/contentUrl")
        if (imageContentUrl) {
            mediaEl = (
                <CardMedia
                    component="img"
                    height="300"
                    image={imageContentUrl}
                    alt={image.getFirstString("https://schema.org/caption")}
                />
            )
        }
    }

    return (
        <Card sx={{backgroundColor: bgColor}}>
            <CardHeader
                // avatar={icon}
                // action={
                //     <IconButton aria-label="settings">
                //         <MoreVertIcon />
                //     </IconButton>
                // }
                title={title}
                subheader={dateText}
            />
            <CardActionArea>
                {mediaEl}
                <CardContent>
                    {/*<Typography gutterBottom variant="h5" component="div">*/}
                    {/*    {title}*/}
                    {/*</Typography>*/}
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {description}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {/*<CardActions>*/}
            {/*    <Button size="small" color="primary">*/}
            {/*        Share*/}
            {/*    </Button>*/}
            {/*</CardActions>*/}
        </Card>
    )
}
