import {createTheme} from "@mui/material";
import {deDE as deDeMuiLocale} from '@mui/material/locale';
import AppFwApp from "../../appfw/ui/AppFwApp.tsx";
import EventerRoutes from "../../EventerRoutes.ts";
import {Navigation} from "@toolpad/core";
import {Event, Settings, SettingsEthernet} from "@mui/icons-material";
import {useAccount} from "./pages/accounts/Accounts.tsx";

const theme = createTheme({
        palette: {

            primary: {
                main: "#256377",
                contrastText: "#eee",
            },

            secondary: {
                main: "#fff",
                // contrastText: "#111",
            },

            // text: {
            //     primary: "#111",
            // }

            // There is a bug, IconButton is not using the right text color for its icons
            // https://github.com/mui/material-ui/issues/33054
            // Workaround: use color="info", which will take the "background color".
            // TODO: wait for a bugfix and remove this palette + color="info" hacks
            // info: {
            //     main: "#fff",
            //     // main: "#000",
            // }
        },
    },
    deDeMuiLocale,
)

export default function App() {

    const account = useAccount()

    // TODO: use memo?
    const navigation: Navigation = [
        {
            segment: EventerRoutes.accounts.account.events.path(account.id).substring(1),
            pattern: EventerRoutes.accounts.account.events.pathTemplate().substring(1),
            title: "Veranstaltungen",
            icon: <Event/>,
        },
        {
            segment: EventerRoutes.accounts.account.importers.path(account.id).substring(1),
            pattern: EventerRoutes.accounts.account.importers.pathTemplate().substring(1),
            title: "Importer",
            icon: <SettingsEthernet/>,
        },
        {
            kind: 'divider',
        },
        {
            segment: EventerRoutes.accounts.account.settings.path(account.id).substring(1),
            pattern: EventerRoutes.accounts.account.settings.pathTemplate().substring(1),
            title: "Einstellungen",
            icon: <Settings/>,
        },
    ]

    // TODO: use memo?
    const branding = {
        title: 'Eventer Console',
        homeUrl: EventerRoutes.accounts.account.path(account.id),
        logo: (<></>),
    }

    return (
        <AppFwApp theme={theme} branding={branding} navigation={navigation}/>
    )
}