import { Outlet } from 'react-router';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';

export default function ToolpadLayout() {
    return (
        <DashboardLayout>
                <Outlet />
            {/*<PageContainer>*/}
            {/*    <Outlet />*/}
            {/*</PageContainer>*/}
        </DashboardLayout>
    );
}