
let Semantic = {

    Predicates: {
        eventStatus: {
            uri: "https://schema.org/eventStatus",

            eventScheduled: "https://schema.org/EventScheduled",
        }
    },
}

export default Semantic