
class _EventerRoutes {
    segment(): string { return "" }
    pathTemplate(): string { return this.path() }
    path(): string { return "/" }

    accounts = {
        segment: () => "accounts",
        pathTemplate: () => this.accounts.segment(),
        path: () => `${this.segment()}/${this.accounts.segment()}`,

        new: {
            segment: () => "new",
            pathTemplate: () => this.accounts.new.path(),
            path: () => `${this.accounts.path()}/${this.accounts.new.segment()}`,
        },

        account: {
            segment: () => ":accountId",
            pathTemplate: () => this.accounts.account.path(this.accounts.account.segment()),
            path: (accountId: string) => `${this.accounts.path()}/${accountId}`,

            events: {
                segment: () => "events",
                pathTemplate: () => this.accounts.account.events.path(this.accounts.account.segment()),
                path: (accountId: string) => `${this.accounts.account.path(accountId)}/${this.accounts.account.events.segment()}`,
            },

            importers: {
                segment: () => "importers",
                pathTemplate: () => this.accounts.account.importers.path(this.accounts.account.segment()),
                path: (accountId: string) => `${this.accounts.account.path(accountId)}/${this.accounts.account.importers.segment()}`
            },

            settings: {
                segment: () => "settings",
                pathTemplate: () => this.accounts.account.settings.path(this.accounts.account.segment()),
                path: (accountId: string) => `${this.accounts.account.path(accountId)}/${this.accounts.account.settings.segment()}`
            },
        }
    }
}

const EventerRoutes = new _EventerRoutes()
export default EventerRoutes
